import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MobileService {
  private _isMobile: boolean;

  constructor() {}

  get isMobile() {
    return JSON.parse(localStorage.getItem("isMobile")) || false;
  }

  set isMobile(isMobile: boolean) {
    this._isMobile = isMobile;
  }
}
